exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-sleep-dentistry-js": () => import("./../../../src/pages/about-sleep-dentistry.js" /* webpackChunkName: "component---src-pages-about-sleep-dentistry-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-an-appointment-js": () => import("./../../../src/pages/make-an-appointment.js" /* webpackChunkName: "component---src-pages-make-an-appointment-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-our-team-meet-your-doctor-js": () => import("./../../../src/pages/our-team/meet-your-doctor.js" /* webpackChunkName: "component---src-pages-our-team-meet-your-doctor-js" */),
  "component---src-pages-patient-information-js": () => import("./../../../src/pages/patient-information.js" /* webpackChunkName: "component---src-pages-patient-information-js" */),
  "component---src-pages-patient-instructions-js": () => import("./../../../src/pages/patient-instructions.js" /* webpackChunkName: "component---src-pages-patient-instructions-js" */),
  "component---src-pages-patient-instructions-post-operative-instructions-adult-patients-js": () => import("./../../../src/pages/patient-instructions/post-operative-instructions-adult-patients.js" /* webpackChunkName: "component---src-pages-patient-instructions-post-operative-instructions-adult-patients-js" */),
  "component---src-pages-patient-instructions-post-operative-instructions-pediatric-patients-js": () => import("./../../../src/pages/patient-instructions/post-operative-instructions-pediatric-patients.js" /* webpackChunkName: "component---src-pages-patient-instructions-post-operative-instructions-pediatric-patients-js" */),
  "component---src-pages-patient-instructions-pre-operative-instructions-adult-patients-js": () => import("./../../../src/pages/patient-instructions/pre-operative-instructions-adult-patients.js" /* webpackChunkName: "component---src-pages-patient-instructions-pre-operative-instructions-adult-patients-js" */),
  "component---src-pages-patient-instructions-pre-operative-instructions-pediatric-patients-js": () => import("./../../../src/pages/patient-instructions/pre-operative-instructions-pediatric-patients.js" /* webpackChunkName: "component---src-pages-patient-instructions-pre-operative-instructions-pediatric-patients-js" */),
  "component---src-pages-referring-doctors-js": () => import("./../../../src/pages/referring-doctors.js" /* webpackChunkName: "component---src-pages-referring-doctors-js" */)
}

